import { Component, OnInit } from "@angular/core";
import { ApiConfiguration } from "src/app/services/http/api-configuration";
import { ApiService } from "src/app/services/http/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { AuthenticationCheckService } from "src/app/services/auth/authentication-check.service";
import { ToastClientService } from "src/app/services/toast/toast-client.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { filter, pairwise } from "rxjs/operators";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  loginForm: any;
  setupForm: any;
  ClearDbForm: any;
  tempOptionModel: any;
  programs: any = [];
  errorMessage: Boolean = true;
  submitted = false;
  passwordSubmitted = false;
  hideClearDB: boolean = true;
  clearDBsubmit = false;

  inputPasswordType: string = "password";

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService,
    public modalService: NgbModal,
    public location: Location
  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({
      username: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
      program_id: ["", Validators.compose([Validators.required])],
    });
    this.setupForm = formBuilder.group({
      password: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
    });

    this.ClearDbForm = formBuilder.group({
      password: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
      program_id: ["", Validators.compose([Validators.required])],
    });

    this.loginForm.valueChanges.subscribe((data) => {});
  }
  get c() {
    return this.loginForm.controls;
  }
  get p() {
    return this.setupForm.controls;
  }
  get db() {
    return this.ClearDbForm.controls;
  }
  ngOnInit() {
    this.getPrograms();
  }

  checkLogin() {
    // debugger
    let previousURL = localStorage.getItem("previousURL");
    let login = localStorage.getItem("login");
    if (login == "true") {
      this.router.navigateByUrl(previousURL);
    }
  }

  getPrograms() {
    this.apiService.get(this.apiConfig.get_all_the_programs).subscribe(
      (res: any) => {
        this.programs = res.data;
        // this.programs = [];
      },
      (err) => {}
    );
  }

  toggleShowPassword() {
    this.inputPasswordType == "password" ? (this.inputPasswordType = "text") : (this.inputPasswordType = "password");
    setTimeout(() => {
      this.inputPasswordType = "password";
    }, 1000);
  }

  programSetup(formData) {
    this.passwordSubmitted = true;
    if (this.setupForm.invalid) {
      return;
    }
    if (formData.password == 123456) {
      this.router.navigateByUrl("create-new-program");
    } else {
      this.errorMessage = false;
    }
    // this.apiService.get(this.apiConfig.setupProgram + formData.password)
    //   .subscribe((res: any) => {
    //     this.toast.Success('Project setup successfully completed');
    //     this.getPrograms();
    //   }, err => {
    //     // this.toast.Error(err.message)
    //   })
  }

  login(formData) {
    //debugger
    this.submitted = true;
    //
    if (this.loginForm.invalid) {
      return;
    }
    let apiUrl = this.apiConfig.login;
    let body = JSON.stringify({
      LoginForm: { username: formData.username, password: formData.password, program_id: formData.program_id },
    });
    window.localStorage.setItem("program", JSON.stringify({ program_id: formData.program_id }));

    this.apiService.post(apiUrl, body).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.errorMessage = true;
          let body = res.body;
          this.toast.Success("Login successful");
          localStorage.setItem("user_name", res.body.data.user_name);
          localStorage.setItem("user_id", res.body.data.id);
          window.localStorage.setItem("program", JSON.stringify(res.body.data));
          this.auth.saveAuth(body.data.access_token);
          this.submitted = false;
          if (body.data.user_role == 1 || body.data.role_name == "Organization" || body.data.profile_code == "GNRL") {
            this.router.navigate(["hubAdmin"]);
            this.callProgramConfigApi();
          } else if (body.data.role_name == "Staff") {
            this.router.navigate(["teamleader"]);
          } else if (body.data.role_name == "Agent" && body.data.abd_id != "ABDAgent") {
            this.router.navigate(["agent"]);
          } else if (body.data.role_name == "Redemption") {
            this.router.navigate(["redemption"]);
          } else if (body.data.role_name == "Reporting") {
            this.router.navigate(["reports"]);
          } else if (body.data.role_name == "Agent" && body.data.abd_id == "ABDAgent") {
            this.router.navigate(["enrollment-approval"]);
          } else {
            this.toast.Success("You dont have access to this module");
          }
        }
      },
      (err) => {
        this.errorMessage = false;
        //
      }
    );
  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }

  toggleView() {
    this.hideClearDB = !this.hideClearDB;
  }

  modalRef;
  ClearDbSubmit(value, target) {
    this.clearDBsubmit = true;
    if (this.ClearDbForm.invalid) {
      return;
    }
    this.modalRef = this.modalService.open(target, {
      centered: true,
      backdrop: "static",
      size: "xl",
      scrollable: true,
      windowClass: "",
    });
    this.modalRef.result.then((result) => {
      if (result) {
      }
    });
  }

  clearDB(value) {
    // return

    let sendData = {
      pwd: value.password,
      program_id: value.program_id,
    };
    // return
    this.apiService.get(this.apiConfig.clearDataBase + "?pwd=" + value.password).subscribe(
      (res: any) => {
        this.toggleView();
        this.modalRef.dismiss();
        this.toast.Success("Database cleared successfully");
        this.getPrograms();
      },
      (err) => {}
    );
  }

  callProgramConfigApi() {
    let apiUrl = this.apiConfig.programConfig;
    this.apiService.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status == 200) {
          //
          localStorage.setItem("has_beat", res.data.has_beat);
          localStorage.setItem("has_channel", res.data.has_channel);
          this.apiService.$beatResonseData.emit(res.data.has_beat);
          this.apiService.$channelResonseData.emit(res.data.has_channel);
        }
      },
      (err) => {}
    );
  }
}
